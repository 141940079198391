import React from 'react';
import Form from '@rjsf/bootstrap-4';
import { toast } from 'react-toastify';

import { createCalendar } from 'api/calendars';

import useCalendarSchemas from './useCalendarSchemas';

/**
 * This component will throw a console error, but it's okay.
 * see https://github.com/rjsf-team/react-jsonschema-form/issues/2648
 */
export default function CreateCalendarForm({ id, onSuccess }) {
  const { schema, uiSchema } = useCalendarSchemas([
    'name',
    'metric_label',
    'default_initial_view',
    'calendar_event_box_color',
    'tag_label_singular',
    'tag_label_plural',
    'has_tags_view'
  ]);
  const [formData, setFormData] = React.useState(null);

  async function handleSubmit({ formData }) {
    const [res, err] = await createCalendar(formData);
    if (err) {
      toast.error(err);
      return;
    }
    toast.success(`Calendar ${res.data.name} created succesfully.`);
    setFormData(null);
    onSuccess(res.data);
  }

  return (
    <Form
      id={id}
      onSubmit={handleSubmit}
      schema={schema}
      uiSchema={uiSchema}
      onChange={e => setFormData(e.formData)}
      formData={formData}
    />
  );
}
