import { apiHandlerWithError } from 'api/apiHandler';
import { addQueryParams } from 'api';

export default async function getCapacityReportsCsv(startDate, endDate) {
  const url = addQueryParams(`capacity-reports-csv-download`, { start_date: startDate, end_date: endDate });
  return await apiHandlerWithError({
    method: 'Get',
    responseType: 'blob',
    url: url,
    headers: {
      'Content-Type': 'text/csv'
    }
  });
}
