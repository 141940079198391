/**
 * This is a revised version of components/e-commerce/Customers.js from Falcon
 */
import React from 'react';

import { Table } from 'atoms/misc';

import makeActionColumnFormatter from './makeActionColumnFormatter';

export default function ManageResourceTable({ data, onEditSuccess, handleDelete, columns, EditModal }) {
  const [modalState, setModalState] = React.useState({
    open: false,
    id: null
  });

  return (
    <>
      <div className="table-responsive">
        <Table
          bootstrap4
          keyField="id"
          data={data}
          columns={[
            ...columns,
            {
              dataField: '',
              headerClasses: 'border-0',
              text: '',
              classes: 'border-0 py-2 align-middle',
              formatter: makeActionColumnFormatter(handleDelete, setModalState),
              align: 'right'
            }
          ]}
          bordered={false}
          // This used to contain `table-dashboard`, but that caused the actions dropdown to be hiddden on the bottom row.
          classes="table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
          rowClasses="btn-reveal-trigger border-top border-200"
          headerClasses="bg-200 text-900 border-y border-200"
        />
      </div>
      <EditModal
        {...modalState}
        toggle={() => {
          setModalState(cur => ({ ...cur, open: !cur.open }));
        }}
        onSuccess={onEditSuccess}
      />
    </>
  );
}
