import React from 'react';
import { Redirect } from 'react-router-dom';
import Loader from 'falcon/components/Loader';
import { toast } from 'react-toastify';

import { verifyEmail } from 'api/users';
export default function AccountVerificationLandingPage() {
  const [state, setState] = React.useState('loading');
  React.useEffect(() => {
    const { location } = window;
    const params = new URLSearchParams(location.search);
    async function request() {
      const [, err] = await verifyEmail(params.get('user_id'), params.get('confirmation_token'));
      if (err) {
        toast.error('Verification failed. Try logging in to get another code.');
        setState('error');
        return;
      }
      toast.success('Verification successful. Please log in.');
      setState('success');
    }
    request();
  }, []);

  switch (state) {
    case 'success': {
      return <Redirect to="/auth/login" />;
    }
    case 'error': {
      return <Redirect to="/auth/login" />;
    }
    default: {
      return <Loader />;
    }
  }
}
