import { signIn as apiCall } from 'api';
import { toast } from 'react-toastify';

export default function login(authDispatch) {
  return async function loginAction(username, password) {
    const [response, error] = await apiCall(username, password);
    if (error) {
      if (response.status === 403) {
        toast.error(
          'Login successful, but your email is not verified. A new email verification link has been sent to your email address.'
        );
      } else {
        toast.error('Login failed. Please try again.');
      }
      return;
    }
    authDispatch({
      type: 'SET_USER',
      payload: response.data
    });
    toast.success(`Logged in as ${response.data.username}`);
  };
}
