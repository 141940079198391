import React, { useRef } from 'react';

import { FullCalendarWithLicenseAndPlugins } from 'atoms/calendars';
import { useAuthContext } from 'context/auth';

import { viewOptionsMap, DEFAULT_VIEW } from './views';

export default function Calendar({ setCalendarApi, ...fullCalendarProps }) {
  const calendarRef = useRef();

  React.useEffect(() => {
    // set calendar api from ref
    setCalendarApi(calendarRef.current.getApi());
  }, [calendarRef, setCalendarApi]);

  const {
    authState: { projectLabels }
  } = useAuthContext();

  return (
    <FullCalendarWithLicenseAndPlugins
      filterResourcesWithEvents
      ref={calendarRef}
      views={Object.fromEntries(viewOptionsMap)}
      initialView={DEFAULT_VIEW}
      weekends={true}
      headerToolbar={false}
      stickyHeaderDates={false}
      selectMirror={true}
      editable={true}
      showNonCurrentDates={false}
      resourceAreaHeaderContent={projectLabels.pluralTitle}
      resourceAreaWidth="20%"
      themeSystem="bootstrap"
      {...fullCalendarProps}
    />
  );
}
