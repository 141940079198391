import React from 'react';
import { Card, CardBody } from 'reactstrap';

import FalconCardHeader from 'falcon/components/FalconCardHeader';
import { useCalendarsListContext } from 'context/calendars';
import { CreateCalendarButton } from 'atoms/calendars';

import CalendarTable from './CalendarTable';

const transformCalendar = calendar => ({
  ...calendar,
  avatar: {
    size: 'xl',
    name: calendar.name,
    round: 'circle'
  }
});

export default function CalendarManagementSection() {
  const { calendars, calendarsDispatch } = useCalendarsListContext();

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Manage Calendars" light={false}>
        <CreateCalendarButton
          buttonIconProps={{
            icon: 'plus',
            transform: 'shrink-3 down-2',
            color: 'falcon-default',
            size: 'sm'
          }}
          onSuccess={newCalendar => {
            calendarsDispatch({
              type: 'ADD_CALENDAR',
              payload: newCalendar
            });
          }}
        >
          New
        </CreateCalendarButton>
      </FalconCardHeader>
      <CardBody className="p-0">
        <CalendarTable
          calendars={calendars.map(transformCalendar)}
          onEditSuccess={updatedCalendar => {
            calendarsDispatch({
              type: 'UPDATE_CALENDAR_DETAILS',
              id: updatedCalendar.id,
              payload: updatedCalendar
            });
          }}
          onDeleteSuccess={deletedId => {
            calendarsDispatch({
              type: 'DELETE_CALENDAR',
              id: deletedId
            });
          }}
        />
      </CardBody>
    </Card>
  );
}
