import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

export default function StyledTooltip({ children, ...tooltipProps }) {
  return (
    <UncontrolledTooltip innerClassName="bg-white border" {...tooltipProps}>
      <div className="text-left font-weight-normal bg-white text-black">{children}</div>
    </UncontrolledTooltip>
  );
}
