import React, { useContext } from 'react';
// TODO: Make a nicer loading screen while auth is checked.
import CircularProgress from '@material-ui/core/CircularProgress';

import { useMount } from 'falcon/helpers/utils';

import { reducer, initialState } from './reducer';
import * as actions from './actions';

const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [authState, authDispatch] = React.useReducer(reducer, initialState);
  useMount(() => {
    actions.checkSession(authDispatch)();
  });

  return (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {authState.sessionChecked ? children : <CircularProgress />}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }

  const authActions = Object.entries(actions).reduce((acc, [name, action]) => {
    acc[name] = action(context.authDispatch);
    return acc;
  }, {});

  return {
    ...context,
    authActions
  };
}
