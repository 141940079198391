import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'falcon/components/Loader';

import { useAuthContext } from 'context/auth';

export default function PermissionRequired({ children, fallback, permission }) {
  const { authState } = useAuthContext();
  const { user, sessionChecked } = authState;

  if (!sessionChecked) return <Loader />;

  if (user.allPermissions.includes(permission)) return children;

  return fallback;
}

PermissionRequired.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
  permission: PropTypes.string.isRequired
};

PermissionRequired.defaultProps = {
  fallback: null
};
