import React from 'react';
import Select from 'react-select';

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled }) => {
    return {
      ...styles,
      color: isDisabled ? '#ccc' : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default'
    };
  },
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: data.color
  }),
  multiValueLabel: styles => ({
    ...styles,
    color: `#FFFFFF`
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: `white`
  })
};

export default function MultiSelect({ ...reactSelectProps }) {
  return <Select closeMenuOnSelect={false} isMulti styles={colourStyles} {...reactSelectProps} />;
}
