import React from 'react';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';
import Loader from 'falcon/components/Loader';
import FalconCardHeader from 'falcon/components/FalconCardHeader';
import Notification from 'falcon/components/Notification';
import ButtonIcon from './ButtonIcon';
import { isIterableArray } from 'falcon/helpers/utils';

const ActivityLog = ({ loading, activities, toggleRefresh }) => {
  return (
    <Card>
      <FalconCardHeader title="Activity log">
        <ButtonIcon icon="sync" onClick={toggleRefresh} color="falcon-default" aria-label="refresh activity log" />
      </FalconCardHeader>
      <CardBody className="fs--1 p-0">
        {loading ? (
          <Loader />
        ) : isIterableArray(activities) ? (
          activities.map((activity, index) => {
            const roundedClass = activities.length === index + 1 ? 'rounded-top-0' : 'rounded-0';

            return (
              <Notification
                key={index}
                className={`border-x-0 border-bottom-0 border-300 ${roundedClass}`}
                {...activity}
              />
            );
          })
        ) : (
          <Row className="p-card">
            <Col>
              <Alert color="info" className="mb-0">
                No activity found
              </Alert>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default ActivityLog;
