import React from 'react';
import { Redirect } from 'react-router-dom';

import Loader from 'falcon/components/Loader';

import { useCalendarsListContext } from 'context/calendars';

const RedirectToFirstCalendar = () => {
  const { calendars } = useCalendarsListContext();

  if (!calendars || calendars.length === 0) {
    return <Loader />;
  }

  return <Redirect to={`calendars/${calendars[0].id}`} />;
};

export default RedirectToFirstCalendar;
