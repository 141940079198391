import React from 'react';
import { Media } from 'reactstrap';
import { PropTypes } from 'prop-types';

import CircleStackIcon from './CircleStackIcon';

export default function MediaWithIconAndHeading({ icon, heading, content, children }) {
  return (
    <Media className="mt-3">
      <CircleStackIcon iconName={icon} />
      <Media body>
        <>
          <h6>{heading}</h6>
          {children || <div className="mb-0 text-justify">{content}</div>}
        </>
      </Media>
    </Media>
  );
}

MediaWithIconAndHeading.propTypes = {
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  // children overrides content, but at least one should be included
  content: PropTypes.node,
  children: PropTypes.node
};

MediaWithIconAndHeading.defaultProps = {
  children: null,
  content: ''
};
