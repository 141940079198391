import React from 'react';

import ButtonIcon from 'falcon/components/ButtonIcon';

import CreateCalendarModal from './CreateCalendarModal';

export default function CreateCalendarButton({ onSuccess = () => {}, buttonIconProps = {} }) {
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <ButtonIcon
        icon="plus"
        onClick={() => {
          setModalOpen(true);
        }}
        {...buttonIconProps}
      >
        Add Calendar
      </ButtonIcon>
      <CreateCalendarModal
        open={modalOpen}
        toggle={() => {
          setModalOpen(cur => !cur);
        }}
        onSuccess={newCalendar => {
          onSuccess(newCalendar);
          setModalOpen(false);
        }}
      />
    </>
  );
}
