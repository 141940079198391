import formatDate from './formatDate';

// Extend this function as more is needed.
export default function formatBody(body, dateFields = ['start', 'end']) {
  const formattedBody = { ...body };
  for (let field of dateFields) {
    formattedBody[field] = formatDate(body[field]);
  }
  return formattedBody;
}
