import { createCalendarItem } from 'api/calendar-items';

export default async function addEventFromBacklog(e, setCalendarItems, setError, setBacklogProjects) {
  const { start, end, extendedProps } = e.event;
  const { calendar, calendar_metric_value, project } = extendedProps;

  const [createCalendarItemResponse, createCalendarItemError] = await createCalendarItem(calendar, {
    start,
    end,
    calendar_metric_value,
    project: project.id
  });
  e.revert();
  if (createCalendarItemError) {
    setError(createCalendarItemError);
    return;
  }
  setCalendarItems(cur => [...cur, createCalendarItemResponse.data]);
  setBacklogProjects(cur => cur.filter(p => p.id !== project.id));
}
