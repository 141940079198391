import React from 'react';

const CalendarSlotDayBox = ({ id, text }) => {
  return (
    <div className="text-wrap w-100 h-100 d-flex align-items-center justify-content-center" id={id}>
      <strong>{text}</strong>
    </div>
  );
};

export default CalendarSlotDayBox;
