import { apiHandlerWithError } from '../apiHandler';

const signIn = async (username, password) => {
  return await apiHandlerWithError.post('sessions', {
    username,
    password
  });
};

export default signIn;
