import React from 'react';

import { StyledTooltip } from 'atoms/misc';

export default function CalendarSlotDayTooltip({ calendarSumLookupById, calendarsLookup, date, ...tooltipProps }) {
  return (
    <StyledTooltip {...tooltipProps} className="toolip-no-flicker">
      <div>
        <p className="mb-0">{date}</p>
        {calendarSumLookupById &&
          Object.entries(calendarSumLookupById).map(([calendarId, calendarSum]) => {
            return (
              <p key={calendarId} className="mb-0">
                <b>{calendarsLookup[calendarId].name} :</b>
                <span>
                  &nbsp;{calendarSum} {calendarsLookup[calendarId].metric_label}
                </span>
              </p>
            );
          })}
      </div>
    </StyledTooltip>
  );
}
