import React from 'react';

const defaultFormatters = {
  eventFormatter: event => event,
  resourceFormatter: () => []
};

/*
Based on information about the calendar's views, returns helpers for handling views.
  selectCalendarViewDropdownProps: Props to spread into SelectCalendarViewDropdown
  calendarProps: Props to spread into Calendar
  formatters: transformers for calendar resources and events for the specified view
*/
export default function useCalendarViews(
  calendarApi,
  viewsMap,
  initialView,
  resourceFormatters = null,
  calendarData = {}
) {
  const [currentViewType, setCurrentViewType] = React.useState(initialView);

  const handleFilter = React.useCallback(
    filter => {
      if (!calendarApi) return;
      setCurrentViewType(filter);
      calendarApi.changeView(filter);
    },
    [calendarApi]
  );

  React.useEffect(() => {
    handleFilter(initialView);
  }, [initialView, calendarApi, handleFilter]);

  const getResourceFormatters = viewType => {
    if (!viewType || !resourceFormatters) return defaultFormatters;
    const resourceType = viewsMap.get(viewType).resources;
    if (!resourceType) return defaultFormatters;
    return resourceFormatters[resourceType];
  };

  let availableFilters = Array.from(viewsMap.keys());
  // TODO: If we want to add more control to view options for calendars,
  // the info could be sent from the backend and this could just be a lookup in
  // calendarData.available_views or similar.
  if (!calendarData?.has_tags_view) {
    availableFilters = availableFilters.filter(el => el !== 'tagResourceView');
  }

  return {
    selectCalendarViewDropdownProps: {
      currentFilter: currentViewType,
      handleFilter,
      filters: availableFilters,
      labelFormatter: filterKey => {
        const buttonText = viewsMap.get(filterKey).buttonText;
        if (typeof buttonText !== 'function') return buttonText;
        return buttonText(calendarData);
      }
    },
    calendarProps: {
      views: Object.fromEntries(viewsMap),
      ...(viewsMap.get(currentViewType)?.calendarProps || {})
    },
    formatters: getResourceFormatters(currentViewType)
  };
}
