import React from 'react';

import ButtonIcon from 'falcon/components/ButtonIcon';

import { useAuthContext } from 'context/auth';

import CreateProjectModal from './CreateProjectModal';

export default function CreateProjectButton({ onSuccess = () => {}, buttonIconProps = {} }) {
  const {
    authState: { projectLabels }
  } = useAuthContext();

  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <ButtonIcon
        icon="plus"
        onClick={() => {
          setModalOpen(true);
        }}
        {...buttonIconProps}
      >
        Add {projectLabels.singularTitle}
      </ButtonIcon>
      <CreateProjectModal
        open={modalOpen}
        toggle={() => {
          setModalOpen(cur => !cur);
        }}
        onSuccess={onSuccess}
      />
    </>
  );
}
