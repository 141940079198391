import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';

const FullCalendarWithLicenseAndPlugins = React.forwardRef(function FullCalendarWithLicenseAndPlugins(
  fullCalendarProps,
  ref
) {
  return (
    <FullCalendar
      height="100%"
      ref={ref}
      schedulerLicenseKey={process.env.REACT_APP_FULL_CALENDAR_LICENSE_KEY}
      plugins={[
        dayGridPlugin,
        bootstrapPlugin,
        timeGridPlugin,
        interactionPlugin,
        listPlugin,
        resourceTimelinePlugin,
        resourceDayGridPlugin
      ]}
      {...fullCalendarProps}
    />
  );
});

export default FullCalendarWithLicenseAndPlugins;
