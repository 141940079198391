/**
 * There are 2 handlers in this file.
 * `apiHandlerWithError` should be preferred.
 * `apiHandlerWithError` was copied from the genesis project,
 * because it knows how to handle the different kinds of responses that Django REST might return.
 * Most importantly: the error messages, which can differ in format significantly.
 * This has not been applied across the whole project yet,
 * but should be preferred whenever adding/changing api calls.
 *
 * TODO: switch to apiHandlerWithError across whole project.
 */
import axios from 'axios';

const apiHandler = axios.create({
  mode: 'same-origin',
  headers: {
    'Content-Type': 'application/json'
  }
});

apiHandler.defaults.baseURL = '/api/';
apiHandler.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
apiHandler.defaults.xsrfCookieName = 'csrftoken';

export default apiHandler;

// This apiHandler always returns a 2-length array with [response, errorMessage]
const apiHandlerWithError = axios.create({
  mode: 'same-origin',
  headers: {
    'Content-Type': 'application/json'
  }
});

function errorHandler(error) {
  const { response } = error;
  const { data, status } = response;

  if (status >= 500) {
    return [response, 'Internal Server Error. Technical Details: ' + JSON.stringify(data, null, 2)];
  }

  if (status === 400) {
    if (Array.isArray(data)) {
      // Django Rest usually returns an array for 400 validation errors.
      return [response, data.join(', ')];
    } else {
      // This is an edge case. Sometimes it sends an object.
      // TODO: Research what that object would look like and parse it instead.
      return [response, JSON.stringify(data)];
    }
  }
  // If it was a DRF response, data.detail. Regular Django response will not have that (just html).
  return [response, data.detail || data];
}

apiHandlerWithError.interceptors.response.use(response => [response, undefined], errorHandler);
apiHandlerWithError.defaults.baseURL = '/api/';
apiHandlerWithError.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
apiHandlerWithError.defaults.xsrfCookieName = 'csrftoken';

export { apiHandlerWithError };
