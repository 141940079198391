import React, { useEffect, useRef } from 'react';

import { FullCalendarWithLicenseAndPlugins } from 'atoms/calendars';

const Calendar = ({ setCalendarApi, editable = true, ...fullCalendarProps }) => {
  const calendarRef = useRef();

  useEffect(() => {
    // set calendar api from ref
    setCalendarApi(calendarRef.current.getApi());
  }, [calendarRef, setCalendarApi]);
  return (
    <FullCalendarWithLicenseAndPlugins
      ref={calendarRef}
      weekends={true}
      headerToolbar={false}
      stickyHeaderDates={false}
      droppable={true}
      editable={editable}
      selectable={true}
      selectMirror={true}
      dayMaxEvents={50}
      themeSystem="bootstrap"
      eventTimeFormat={{
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: true,
        meridiem: true
      }}
      showNonCurrentDates={false}
      {...fullCalendarProps}
    />
  );
};

export default Calendar;
