import { toTitleCase } from 'utils/formatters';

function transformCalendar(calendar) {
  return {
    ...calendar,
    tagLabels: {
      singularTitle: toTitleCase(calendar.tag_label_singular),
      pluralTitle: toTitleCase(calendar.tag_label_plural),
      singular: calendar.tag_label_singular,
      plural: calendar.tag_label_plural
    }
  };
}

export function reducer(currentState, action) {
  switch (action.type) {
    case 'SET_CALENDARS': {
      return action.payload.map(transformCalendar);
    }
    case 'UPDATE_CALENDAR_DETAILS': {
      return currentState.map(el => {
        if (el.id !== action.id) return el;
        return transformCalendar(action.payload);
      });
    }
    case 'DELETE_CALENDAR': {
      return currentState.filter(el => el.id !== action.id);
    }
    case 'ADD_CALENDAR': {
      return [...currentState, action.payload];
    }
    default:
      throw new Error(`Calendars dispatch received unhandled dispatch action type: ${action.type}`);
  }
}
