import React from 'react';
import { Card, CardBody } from 'reactstrap';

import FalconCardHeader from 'falcon/components/FalconCardHeader';
import { getUsers } from 'api/users';
import { CreateUserButton } from 'atoms/users';

import UserTable from './UserTable';

const transformUser = ({ id, groups, first_name, last_name, username, userdetails: { profile_img_url } }) => ({
  id,
  groups,
  name: `${first_name} ${last_name}`,
  avatar: {
    size: 'xl',
    name: `${first_name} ${last_name}`,
    round: 'circle',
    src: profile_img_url
  },
  username
});

export default function UserManagementSection() {
  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    async function callApi() {
      const [res, err] = await getUsers();
      if (err) {
        setUsers([]);
        return;
      }
      setUsers(res.data.map(transformUser));
    }
    callApi();
  }, []);

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Manage Users" light={false}>
        <CreateUserButton
          buttonIconProps={{
            icon: 'plus',
            transform: 'shrink-3 down-2',
            color: 'falcon-default',
            size: 'sm'
          }}
          onSuccess={newUser => {
            setUsers(cur => [...cur, transformUser(newUser)]);
          }}
        >
          New
        </CreateUserButton>
      </FalconCardHeader>
      <CardBody className="p-0">
        <UserTable
          users={users}
          onEditSuccess={updatedUser => {
            setUsers(cur =>
              cur.map(el => {
                if (el.id !== updatedUser.id) return el;
                return transformUser(updatedUser);
              })
            );
          }}
          onDeleteSuccess={deletedUserId => setUsers(cur => cur.filter(el => el.id !== deletedUserId))}
        />
      </CardBody>
    </Card>
  );
}
