import React from 'react';
import { Input, FormGroup, Label } from 'reactstrap';

export default function CheckboxWithLabel({ className, label, id, ...inputProps }) {
  return (
    <FormGroup check className={className}>
      <Input id={id} className="cursor-pointer" type="checkbox" {...inputProps} />
      <Label className="cursor-pointer" check for={id}>
        {label}
      </Label>
    </FormGroup>
  );
}
