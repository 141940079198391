import React from 'react';
import { toast } from 'react-toastify';

import { getCalendarsList } from 'api/calendars';
import { useCalendarViews } from 'atoms/calendars';

import { reducer } from './reducer';

const CalendarsListContext = React.createContext();

export function CalendarsListProvider({ children }) {
  const [calendars, calendarsDispatch] = React.useReducer(reducer, []);

  React.useEffect(() => {
    async function request() {
      const [res, err] = await getCalendarsList();
      if (err) {
        toast.error(err);
        return;
      }
      calendarsDispatch({
        type: 'SET_CALENDARS',
        payload: res.data
      });
    }
    request();
  }, []);

  return (
    <CalendarsListContext.Provider value={{ calendars, calendarsDispatch }}>{children}</CalendarsListContext.Provider>
  );
}

export function useCalendarsListContext() {
  return React.useContext(CalendarsListContext);
}

export function useCalendar(id, calendarApi, viewsMap, resourceFormatters) {
  const { calendars } = useCalendarsListContext();
  const calendarData = calendars.find(el => String(el.id) === id);

  const { selectCalendarViewDropdownProps, calendarProps, formatters } = useCalendarViews(
    calendarApi,
    viewsMap,
    calendarData?.default_initial_view,
    resourceFormatters,
    calendarData
  );

  return {
    calendarData,
    selectCalendarViewDropdownProps,
    calendarProps,
    formatters
  };
}
