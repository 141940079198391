import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';

import { patchCalendar } from 'api/calendars';
import { EditableFieldWithPatchRequest, CheckboxWithLabel } from 'atoms/form';
import { MediaWithIconAndHeading } from 'atoms/misc';
import { useCalendarsListContext } from 'context/calendars';

export default function EditCalendarModal({ open, toggle, id, onSuccess = () => {} }) {
  const { calendars } = useCalendarsListContext();
  const calendar = calendars?.find(el => el.id === id);

  if (!calendar) return null;

  const patchRequest = async body => await patchCalendar(id, body);

  function onUpdateSuccess(updatedCalendar) {
    toast.success('Calendar updated.');
    onSuccess(updatedCalendar);
  }

  async function handleCheckbox(e, fieldName) {
    const [res, err] = await patchRequest({ [fieldName]: e.target.checked });
    if (err) {
      toast.error(err);
      return;
    }
    onUpdateSuccess(res.data);
  }

  return (
    <Modal isOpen={open} toggle={toggle} modalClassName="theme-modal" contentClassName="border">
      <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0">
        <span className="mr-3">Edit Calendar "{calendar.name}"</span>
      </ModalHeader>
      <ModalBody>
        <MediaWithIconAndHeading icon="user" heading="Name">
          <EditableFieldWithPatchRequest
            label="Name"
            initialValue={calendar.name}
            patchRequest={patchRequest}
            fieldName="name"
            onSuccess={onUpdateSuccess}
          />
        </MediaWithIconAndHeading>
        <MediaWithIconAndHeading icon="dollar-sign" heading="Metric Label">
          <EditableFieldWithPatchRequest
            label="Metric Label"
            initialValue={calendar.metric_label}
            patchRequest={patchRequest}
            fieldName="metric_label"
            onSuccess={onUpdateSuccess}
          />
        </MediaWithIconAndHeading>
        <MediaWithIconAndHeading icon="paint-brush" heading="Color">
          <EditableFieldWithPatchRequest
            label="Color"
            initialValue={calendar.calendar_event_box_color}
            patchRequest={patchRequest}
            fieldName="calendar_event_box_color"
            onSuccess={onUpdateSuccess}
            groupedInputProps={{
              type: 'color'
            }}
          />
        </MediaWithIconAndHeading>
        <MediaWithIconAndHeading icon="tag" heading="Tag Label (singular)">
          <EditableFieldWithPatchRequest
            label="Tag Label (singular)"
            initialValue={calendar.tag_label_singular}
            patchRequest={patchRequest}
            fieldName="tag_label_singular"
            onSuccess={onUpdateSuccess}
          />
        </MediaWithIconAndHeading>
        <MediaWithIconAndHeading icon="tag" heading="Tag Label (plural)">
          <EditableFieldWithPatchRequest
            label="Tag Label (plural)"
            initialValue={calendar.tag_label_plural}
            patchRequest={patchRequest}
            fieldName="tag_label_plural"
            onSuccess={onUpdateSuccess}
          />
        </MediaWithIconAndHeading>
        <MediaWithIconAndHeading icon="eye" heading="Tag View Enabled">
          <CheckboxWithLabel
            id="edit-calendar-has-tag-view-checkbox"
            label="Tag View Enabled"
            checked={calendar.has_tags_view}
            onChange={e => {
              handleCheckbox(e, 'has_tags_view');
            }}
          />
        </MediaWithIconAndHeading>
      </ModalBody>
      <ModalFooter className="bg-light px-card border-top-0" />
    </Modal>
  );
}
