import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CircleStackIcon({
  iconName,
  inverse = true,
  circleProps = {},
  iconProps = {},
  circleClassName = 'text-200',
  iconClassName = 'text-primary',
  spanClassName = 'ml-n1 mr-3'
}) {
  return (
    <span className={`fa-stack ${spanClassName}`}>
      <FontAwesomeIcon icon="circle" className={`${circleClassName} fa-stack-2x`} {...circleProps} />
      <FontAwesomeIcon
        icon={iconName}
        transform=""
        className={`${iconClassName} fa-stack-1x`}
        inverse={inverse}
        {...iconProps}
      />
    </span>
  );
}
