import React from 'react';
import { toast } from 'react-toastify';

import { getGroups } from 'api/groups';

export default function useGroups() {
  const [groups, setGroups] = React.useState([]);
  React.useEffect(() => {
    async function request() {
      const [res, err] = await getGroups();
      if (err) {
        toast.error(err);
        return;
      }
      setGroups(res.data);
    }
    request();
  }, []);
  return groups;
}
