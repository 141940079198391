import React from 'react';
import { Button, Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Flex from 'falcon/components/Flex';
import { SelectCalendarViewDropdown } from 'atoms/calendars';
import DownloadCapacityReportsCSVButton from './DownloadCapacityReportsCSVButton';

export default function CalendarHeader({ title, calendarApi, showCsvDownloadButton, selectCalendarViewDropdownProps }) {
  return (
    <Card>
      <CardBody>
        <Row noGutters className="align-items-center">
          <Col xs="auto" className="d-flex justify-content-end order-md-1">
            <UncontrolledTooltip placement="bottom" target="previous">
              Previous
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" target="next">
              Next
            </UncontrolledTooltip>
            <Button
              onClick={() => {
                // Needs to be an inline func for some reason, just calendarApi.prev won't work.
                if (calendarApi) {
                  calendarApi.prev();
                }
              }}
              color="link"
              className="icon-item icon-item-sm icon-item-hover shadow-none p-0 mr-1 ml-md-2"
              id="previous"
            >
              <FontAwesomeIcon icon="arrow-left" />
            </Button>
            <Button
              onClick={() => {
                if (calendarApi) {
                  calendarApi.next();
                }
              }}
              color="link"
              className="icon-item icon-item-sm icon-item-hover shadow-none p-0 mr-1"
              id="next"
            >
              <FontAwesomeIcon icon="arrow-right" />
            </Button>
          </Col>
          <Col xs="auto" md="auto" className="order-md-2">
            <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2 calendar-title">{title}</h4>
          </Col>
          <Col xs md="auto" tag={Flex} justify="end" className="order-md-3">
            <Button
              size="sm"
              color="falcon-primary"
              className="mr-2"
              onClick={() => {
                calendarApi.today();
              }}
            >
              Today
            </Button>
            {showCsvDownloadButton && <DownloadCapacityReportsCSVButton calendarApi={calendarApi} />}
          </Col>
          <Col md="auto" className="d-md-none">
            <hr />
          </Col>
          <Col className="d-flex justify-content-end order-md-2" style={{ zIndex: 1016 }}>
            <SelectCalendarViewDropdown {...selectCalendarViewDropdownProps} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
