import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import Loader from 'falcon/components/Loader';

import { useAuthContext } from 'context/auth';

const AuthRequired = ({ children, redirectUrl }) => {
  const { authState } = useAuthContext();
  const { user, sessionChecked } = authState;

  return <>{!sessionChecked ? <Loader /> : !user ? <Redirect to={redirectUrl} /> : children}</>;
};

AuthRequired.propTypes = {
  children: PropTypes.node.isRequired,
  redirectUrl: PropTypes.string
};

AuthRequired.defaultProps = {
  redirectUrl: '/auth/login'
};

export default AuthRequired;
