import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function makeActionColumnFormatter(handleDelete, setModalState) {
  return function ActionColumnFormatter(_, { id }) {
    return (
      <UncontrolledDropdown>
        <DropdownToggle color="link" size="sm" className="text-600 btn-reveal mr-3">
          <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
        </DropdownToggle>
        <DropdownMenu right className="border py-2">
          <DropdownItem
            onClick={() => {
              setModalState({ open: true, id });
            }}
          >
            Edit
          </DropdownItem>
          <DropdownItem onClick={() => handleDelete(id)} className="text-danger">
            Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };
}
