import React from 'react';
import { toast } from 'react-toastify';

export default function useToastError() {
  const [error, setError] = React.useState(null);
  React.useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);
  return React.useMemo(() => setError, []);
}
