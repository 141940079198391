import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import ButtonIcon from 'falcon/components/ButtonIcon';

import CreateCalendarForm from './CreateCalendarForm';

const FORM_ID = 'create-calendar-form';

export default function CreateCalendarModal({ open, toggle, onSuccess }) {
  return (
    <Modal isOpen={open} toggle={toggle} modalClassName="theme-modal" contentClassName="border">
      <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0">
        Create New Calendar
      </ModalHeader>
      <ModalBody>
        <CreateCalendarForm id={FORM_ID} onSuccess={onSuccess} />
      </ModalBody>
      <ModalFooter>
        <ButtonIcon icon="check" color="falcon-primary" type="submit" className="px-4" form={FORM_ID}>
          Save
        </ButtonIcon>
      </ModalFooter>
    </Modal>
  );
}
