import React from 'react';
import Form from '@rjsf/bootstrap-4';
import { toast } from 'react-toastify';

import { createUser } from 'api/users';

import useUserSchemas from './useUserSchemas';
import useGroups from './useGroups';

export default function CreateUserForm({ id, onSuccess }) {
  const { schema, uiSchema } = useUserSchemas([
    'username',
    'password',
    'first_name',
    'last_name',
    'profile_img_url',
    'groups'
  ]);
  const [formData, setFormData] = React.useState(null);
  const groups = useGroups();

  async function handleSubmit({ formData }) {
    const [res, err] = await createUser({
      ...formData,
      groups: (formData.groups || []).map(s => Number(s)).concat([groups.find(g => g.name === 'Company Users').id]),
      email: formData.username
    });
    if (err) {
      toast.error(err);
      return;
    }
    toast.success(`User ${res.data.username} created succesfully.`);
    setFormData(null);
    onSuccess(res.data);
  }

  return (
    <Form
      id={id}
      onSubmit={handleSubmit}
      schema={schema}
      uiSchema={uiSchema}
      onChange={e => setFormData(e.formData)}
      formData={formData}
    />
  );
}
