import React from 'react';
import { toast } from 'react-toastify';

import { getUserDetails } from 'api/users';

export default function useUserDetails(id) {
  const [user, setUser] = React.useState({});
  const [refresh, setRefresh] = React.useState(false);
  const refreshUserDetails = React.useCallback(() => {
    setRefresh(cur => !cur);
  }, []);
  React.useEffect(() => {
    if (!id) return;
    async function request() {
      const [res, err] = await getUserDetails(id);
      if (err) {
        toast.error(err);
        return;
      }
      setUser(res.data);
    }
    request();
  }, [id, refresh]);
  return { user, refreshUserDetails };
}
