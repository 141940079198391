import React from 'react';
import PropTypes from 'prop-types';
import { Button, InputGroupAddon } from 'reactstrap';

import ButtonIcon from 'falcon/components/ButtonIcon';

const EditFieldAddon = ({ loading, editing, setEditing, onSave, onStartEditing }) => {
  return (
    <InputGroupAddon addonType="append">
      {loading ? (
        <Button aria-label="loading" disabled>
          <span className="spinner-border spinner-border-sm" />
        </Button>
      ) : editing ? (
        <ButtonIcon aria-label="save" icon="check-circle" color="falcon-default" onClick={onSave} />
      ) : (
        <ButtonIcon
          aria-label="edit"
          icon="pencil-alt"
          color="falcon-default"
          onClick={() => {
            setEditing(true);
            onStartEditing && onStartEditing();
          }}
        />
      )}
    </InputGroupAddon>
  );
};

EditFieldAddon.propTypes = {
  loading: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  setEditing: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onStartEditing: PropTypes.func
};

export default EditFieldAddon;
