import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';

import { patchUser } from 'api/users';
import { EditableFieldWithPatchRequest, CheckboxWithLabel } from 'atoms/form';
import { MediaWithIconAndHeading } from 'atoms/misc';
import { GroupedInput } from 'atoms/form';

import useUserDetails from './useUserDetails';
import useGroups from './useGroups';

export default function EditUserModal({ open, toggle, id, onSuccess = () => {} }) {
  const { user, refreshUserDetails } = useUserDetails(id);
  const groups = useGroups();
  const userGroupIds = user.groups?.map(g => g.id) || [];

  const updateUser = async body => await patchUser(id, body);

  function onUpdateSuccess(updatedUser) {
    toast.success('User updated.');
    onSuccess(updatedUser);
    refreshUserDetails();
  }

  async function handleGroupSelect(e, groupId) {
    let newGroups;
    if (e.target.checked) {
      newGroups = [...userGroupIds, groupId];
    } else {
      newGroups = userGroupIds.filter(el => el !== groupId);
    }
    const [res, err] = await updateUser({
      groups: newGroups.concat([groups.find(g => g.name === 'Company Users').id])
    });
    if (err) {
      toast.error(err);
      return;
    }
    onUpdateSuccess(res.data);
  }

  return (
    <Modal isOpen={open} toggle={toggle} modalClassName="theme-modal" contentClassName="border">
      <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0">
        <span className="mr-3">Edit User "{user.username}"</span>
      </ModalHeader>
      <ModalBody>
        <MediaWithIconAndHeading icon="user" heading="Username">
          <GroupedInput label="Username" name="username" value={user.username} disabled={true} />
        </MediaWithIconAndHeading>
        <MediaWithIconAndHeading icon="lock" heading="Password">
          <EditableFieldWithPatchRequest
            label="Password"
            initialValue=""
            patchRequest={updateUser}
            fieldName="password"
            onSuccess={onUpdateSuccess}
            groupedInputProps={{
              type: 'password'
            }}
          />
        </MediaWithIconAndHeading>
        <MediaWithIconAndHeading icon="pencil-alt" heading="First Name">
          <EditableFieldWithPatchRequest
            label="First Name"
            initialValue={user.first_name}
            patchRequest={updateUser}
            fieldName="first_name"
            onSuccess={onUpdateSuccess}
          />
        </MediaWithIconAndHeading>
        <MediaWithIconAndHeading icon="pencil-alt" heading="Last Name">
          <EditableFieldWithPatchRequest
            label="Last Name"
            initialValue={user.last_name}
            patchRequest={updateUser}
            fieldName="last_name"
            onSuccess={onUpdateSuccess}
          />
        </MediaWithIconAndHeading>
        <MediaWithIconAndHeading icon="image" heading="Profile Image URL">
          <EditableFieldWithPatchRequest
            label="Profile Image URL"
            initialValue={user.userdetails?.profile_img_url}
            patchRequest={updateUser}
            fieldName="profile_img_url"
            onSuccess={onUpdateSuccess}
          />
        </MediaWithIconAndHeading>
        <MediaWithIconAndHeading
          icon="list-alt"
          heading="Roles"
          content={groups
            .filter(g => g.name !== 'Company Users')
            .map(group => (
              <CheckboxWithLabel
                key={group.id}
                label={group.name}
                id={String(group.id)}
                checked={userGroupIds?.includes(group.id)}
                onChange={e => {
                  handleGroupSelect(e, group.id);
                }}
              />
            ))}
        />
      </ModalBody>
      <ModalFooter className="bg-light px-card border-top-0" />
    </Modal>
  );
}
