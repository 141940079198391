import { useMemo, useEffect } from 'react';

// Sets the title the component is mounted, sets it back on unmount.
export default function useCustomTitle(title) {
  const documentTitle = useMemo(() => document.title, []);
  useEffect(() => {
    if (title) {
      document.title = title;
    }
    return () => {
      document.title = documentTitle;
    };
  }, [title, documentTitle]);
}
