import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { FalconContext } from 'falcon';
import NavbarTop from './navbar/NavbarTop';
import NavbarVertical from './navbar/NavbarVertical';

const DashboardLayout = ({ children }) => {
  const location = useLocation();
  const { isVertical, navbarStyle } = useContext(FalconContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="container-fluid">
      {isVertical && <NavbarVertical isKanban={true} navbarStyle={navbarStyle} />}
      <div className="content">
        <NavbarTop />
        {children}
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default DashboardLayout;
