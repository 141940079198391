import React from 'react';
import { toast } from 'react-toastify';

import { EditCalendarModal } from 'atoms/calendars';
import { deleteCalendar } from 'api/calendars';

import { ManageResourceTable, NameColumnFormatter } from 'atoms/table';
import { TagBadge } from 'atoms/calendars';

export default function CalendarTable({ calendars, onEditSuccess, onDeleteSuccess }) {
  async function handleDelete(id) {
    if (!window.confirm('Delete calendar and all related calendar items? Are you sure?')) return;
    const [, err] = await deleteCalendar(id);
    if (err) {
      toast.error(err);
      return;
    }
    toast.success('Calendar and all related calendar items deleted.');
    onDeleteSuccess(id);
  }

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: NameColumnFormatter,
      sort: true
    },
    {
      dataField: 'metric_label',
      headerClasses: 'border-0',
      text: 'Metric Label',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'calendar_event_box_color',
      headerClasses: 'border-0',
      text: 'Color',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: function ColorFormatter(color) {
        return <TagBadge color={color} />;
      }
    }
  ];

  return (
    <ManageResourceTable
      data={calendars}
      onEditSuccess={onEditSuccess}
      handleDelete={handleDelete}
      columns={columns}
      EditModal={EditCalendarModal}
    />
  );
}
