import React from 'react';

import DropdownFilter from 'falcon/components/DropdownFilter';

export default function SelectCalendarViewDropdown({ currentFilter, handleFilter, filters, labelFormatter }) {
  return (
    <DropdownFilter
      className="mr-2"
      filters={filters}
      currentFilter={currentFilter}
      handleFilter={handleFilter}
      icon="sort"
      labelFormatter={labelFormatter}
    />
  );
}
