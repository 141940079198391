import React from 'react';
import { Media } from 'reactstrap';

import Flex from 'falcon/components/Flex';
import Avatar from 'falcon/components/Avatar';

export default function NameColumnFormatter(_, { avatar, name }) {
  return (
    <Media tag={Flex} align="center">
      <Avatar {...avatar} />
      <Media body className="ml-2">
        <h5 className="mb-0 fs--1">{name}</h5>
      </Media>
    </Media>
  );
}
