import React from 'react';

const CalendarEventBox = ({ id, color }) => {
  return (
    <div className="text-wrap" id={id} style={{ backgroundColor: color, borderColor: color }}>
      <strong>&nbsp;</strong>
    </div>
  );
};

export default CalendarEventBox;
