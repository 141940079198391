/**
 * This is a revised version of components/e-commerce/Customers.js from Falcon
 */
import React from 'react';
import { toast } from 'react-toastify';

import { EditUserModal } from 'atoms/users';
import { deleteUser } from 'api/users';

import { ManageResourceTable, EmailColumnFormatter, NameColumnFormatter } from 'atoms/table';
import { BooleanCheckmarkOrNull } from 'atoms/misc';

export default function UserTable({ users, onEditSuccess, onDeleteSuccess }) {
  async function handleDelete(id) {
    if (!window.confirm('Delete user? Are you sure?')) return;
    const [, err] = await deleteUser(id);
    if (err) {
      toast.error(err);
      return;
    }
    toast.success('User deleted.');
    onDeleteSuccess(id);
  }

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: NameColumnFormatter,
      sort: true
    },
    {
      dataField: 'username',
      headerClasses: 'border-0',
      text: 'Email',
      classes: 'border-0 py-2 align-middle',
      formatter: EmailColumnFormatter,
      sort: true
    },
    {
      dataField: 'groups',
      headerClasses: 'border-0',
      text: 'Admin',
      classes: 'border-0 py-2 align-middle',
      formatter: function GroupColumn(groups) {
        return <BooleanCheckmarkOrNull bool={Boolean(groups.find(g => g.name === 'Company Admins'))} />;
      }
    }
  ];

  return (
    <ManageResourceTable
      data={users}
      onEditSuccess={onEditSuccess}
      handleDelete={handleDelete}
      columns={columns}
      EditModal={EditUserModal}
    />
  );
}
