import React from 'react';
import ActivityLog from 'falcon/components/ActivityLog';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

import { getProjectActivityLogs } from 'api/projects';

export default function ProjectActivityLog({ projectId }) {
  const [activities, setActivities] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const toggleRefresh = () => {
    setRefresh(!refresh);
  };

  React.useEffect(() => {
    async function request() {
      setLoading(true);
      const [res, err] = await getProjectActivityLogs(projectId);
      if (err) {
        toast.error(err);
      } else {
        setActivities(
          res.data.map(el => ({
            avatar: { emoji: '📜', size: 'm' },
            children: el.detail,
            time: format(new Date(el.created_at), 'PPpp'),
            to: '#!'
          }))
        );
      }
      setLoading(false);
    }
    request();
  }, [projectId, refresh]);

  return <ActivityLog activities={activities} loading={loading} toggleRefresh={toggleRefresh} />;
}
