import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

const Error404 = ({ card, homePath }) => (
  <>
    {card ? (
      <Card className="text-center">
        <CardBody className="p-5">
          <Content homePath={homePath} />
        </CardBody>
      </Card>
    ) : (
      <Content homePath={homePath} />
    )}
  </>
);

Error404.propTypes = {
  card: PropTypes.bool,
  homePath: PropTypes.string.isRequired
};

Error404.defaultProps = {
  card: true
};

const Content = ({ homePath }) => (
  <>
    <div className="display-1 text-200 fs-error">404</div>
    <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
      The page you're looking for is not found.
    </p>
    <hr />
    <p>
      Make sure the address is correct and that the page hasn't moved. If you think this is a mistake, contact an
      administrator.
    </p>
    <Link className="btn btn-primary btn-sm mt-3" to={homePath}>
      <FontAwesomeIcon icon="home" className="mr-2" />
      Take me home
    </Link>
  </>
);

Content.propTypes = {
  homePath: PropTypes.string.isRequired
};

export default Error404;
