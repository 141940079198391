import { signOut as apiCall } from 'api';
import { toast } from 'react-toastify';

export default function logout(authDispatch) {
  return async function logoutAction() {
    // eslint-disable-next-line no-unused-vars
    const [_, error] = await apiCall();
    if (!error) {
      authDispatch({
        type: 'CLEAR_USER'
      });
      toast.success('You have been logged out.');
    } else {
      // TODO: Is there a better way to handle this?
      toast.error('Logout failed.');
    }
  };
}
