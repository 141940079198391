import React from 'react';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter, ModalHeader, Form } from 'reactstrap';

import ButtonIcon from 'falcon/components/ButtonIcon';
import { updateCalendarItems } from 'actions/calendars';
import { putCalendarItemTags } from 'api/calendar-items';
import { MultiSelect } from 'atoms/form';

const transformTags = tags => tags && tags.map(tag => ({ ...tag, value: tag.id }));

const CalendarItemTagsModal = ({
  calendarData,
  open,
  toggle,
  setCalendarItems,
  initialState,
  id,
  transformCalendarItem,
  refreshBacklog,
  onSuccess
}) => {
  const [loadingUpdate, setLoadingUpdate] = React.useState(false);
  const [selectedTags, setSelectedTags] = React.useState([]);

  React.useEffect(() => {
    if (initialState) {
      setSelectedTags(transformTags(initialState.tags));
    }
  }, [initialState]);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoadingUpdate(true);
    const [res, err] = await putCalendarItemTags(id, (selectedTags || []).map(tag => tag.id));
    if (err) {
      toast.error(err);
      setLoadingUpdate(false);
      return;
    }
    onSuccess(res.data);
    updateCalendarItems(transformCalendarItem, setCalendarItems, refreshBacklog, {
      removedItemId: id,
      addedItem: res.data
    });
    setLoadingUpdate(false);
    toggle();
    toast.success('Calendar Item updated.');
  }

  return (
    <Modal isOpen={open} toggle={toggle} modalClassName="theme-modal" contentClassName="border" centered>
      <ModalHeader toggle={toggle} tag="div" className="px-card bg-light border-0 flex-between-center">
        Add or Remove {calendarData?.tagLabels?.pluralTitle}
      </ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody className="px-card pb-card pt-1 fs--1">
          <MultiSelect
            value={selectedTags}
            options={transformTags(calendarData?.tag_set)}
            onChange={value => setSelectedTags(value)}
          />
        </ModalBody>
        <ModalFooter className="bg-light border-top-0">
          <ButtonIcon onClick={toggle} icon="times" color="falcon-default" size="sm">
            Cancel
          </ButtonIcon>
          {loadingUpdate ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            <ButtonIcon type="submit" icon="check" color="falcon-primary" size="sm">
              Ok
            </ButtonIcon>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CalendarItemTagsModal;
