export default function updateCalendarItems(
  transformCalendarItem,
  setCalendarItems,
  refreshBacklog,
  { removedItemId, addedItem } = {}
) {
  setCalendarItems(cur => {
    let result = [...cur];
    if (removedItemId) {
      result = result.filter(el => el.id !== Number(removedItemId));
    }
    if (addedItem) {
      result.push(transformCalendarItem(addedItem));
    }
    return result;
  });
  refreshBacklog();
}
