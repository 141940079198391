import { toast } from 'react-toastify';

import { apiHandlerWithError } from 'api/apiHandler';
import { addQueryParams } from 'api';

export default async function getCalendarCalendarItems(calendarId, setCalendarItems, setError, queryParams = {}) {
  const [res, err] = await apiHandlerWithError(addQueryParams(`calendars/${calendarId}/calendar-items`, queryParams));
  if (err) {
    toast.error('Failed to fetch query results.');
    setError(err);
    return;
  }
  setError(null);
  setCalendarItems(res.data);
}
