import useGroups from './useGroups';

function getProperties(groups, fieldOrder) {
  const properties = {
    username: {
      title: 'Email Address',
      type: 'string'
    },
    password: {
      title: 'Password',
      type: 'string'
    },
    first_name: {
      title: 'First Name',
      type: 'string'
    },
    last_name: {
      title: 'Last Name',
      type: 'string'
    },
    profile_img_url: {
      title: 'Profile Image URL',
      type: 'string'
    },
    groups: {
      type: 'array',
      title: 'Roles',
      items: {
        type: 'string',
        enum: groups.map(group => String(group.id)),
        enumNames: groups.map(group => group.name)
      },
      uniqueItems: true
    }
  };
  return fieldOrder.reduce((acc, fieldName) => {
    acc[fieldName] = properties[fieldName];
    return acc;
  }, {});
}
const uiPropertySettings = {
  username: {
    'ui:widget': 'email'
  },
  password: {
    'ui:widget': 'password'
  },
  groups: {
    'ui:widget': 'checkboxes'
  }
};

/**
 *
 * @param {array} fieldOrder : list of fields to display in the correct order
 * @returns schema and uiSchema to feed into RJSF, which includes only the requested fields
 */
export default function useUserSchemas(fieldOrder) {
  const groups = useGroups().filter(g => g.name !== 'Company Users');
  const schema = {
    type: 'object',
    required: ['username', 'password'].filter(fieldName => fieldOrder.includes(fieldName)),
    properties: getProperties(groups, fieldOrder)
  };
  const uiSchema = {
    'ui:submitButtonOptions': { norender: true },
    'ui:order': fieldOrder,
    ...fieldOrder.reduce((acc, fieldName) => {
      acc[fieldName] = uiPropertySettings[fieldName];
      return acc;
    }, {})
  };
  return { schema, uiSchema };
}
