import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Input, InputGroupText, Label, InputGroupAddon } from 'reactstrap';

const GroupedInput = React.forwardRef(function GroupedInput(
  {
    children,
    label,
    // Use with showLabelText to show a different label than the aria label.
    shortLabel,
    name,
    options,
    value,
    valueFormatter,
    InputComponent,
    showLabelText,
    inputRefPropName,
    ...inputProps
  },
  inputRef
) {
  return (
    <>
      <InputGroup className="mb-1">
        {showLabelText && (
          <InputGroupAddon addonType="prepend">
            <InputGroupText>{shortLabel || label}</InputGroupText>
          </InputGroupAddon>
        )}
        <InputComponent
          id={`${name}-input`}
          name={name}
          value={valueFormatter ? valueFormatter(value) : value}
          {...{ [inputRefPropName]: inputRef }}
          {...inputProps}
        >
          {options &&
            options.map(option => (
              <option key={`option-${option.value}`} value={option.value}>
                {option.label}
              </option>
            ))}
        </InputComponent>
        {children}
      </InputGroup>
      {/* Having the label inside the InputGroup messes with the rounded corners. */}
      <Label hidden for={`${name}-input`}>
        {label}
      </Label>
    </>
  );
});

GroupedInput.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  value: PropTypes.any,
  valueFormatter: PropTypes.func,
  inputRefPropName: PropTypes.string,
  showLabelText: PropTypes.bool,
  InputComponent: PropTypes.elementType,
  shortLabel: PropTypes.string
};

GroupedInput.defaultProps = {
  options: null,
  children: null,
  valueFormatter: null,
  value: '',
  showLabelText: true,
  inputRefPropName: 'innerRef',
  InputComponent: Input
};

export default GroupedInput;
