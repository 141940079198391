export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function camelToTitle(camel) {
  const pascal = camel[0].toUpperCase() + camel.slice(1);
  return pascal.replace(/[A-Z]/g, (m, offset) => {
    return offset === 0 ? m : ' ' + m;
  });
}
