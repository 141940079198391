import 'assets/css/theme.css';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { settings } from 'config';

export const FalconContext = React.createContext(settings);

export const FalconProvider = props => {
  const [navbarStyle, setNavbarStyle] = useState(settings.navbarStyle);

  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(false);
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const [isOpenSidePanel, setIsOpenSidePanel] = useState(false);
  const [navbarCollapsed, setNavbarCollapsed] = useState(false);

  const toggleModal = () => setIsOpenSidePanel(prevIsOpenSidePanel => !prevIsOpenSidePanel);

  // The variables in `settings` should not really live in context, since they never change.
  // However, these variables are pulled from `FalconContext` into the Falcon template in a great number of places,
  // so we can't just remove them.
  // The bright side of keeping them in context is that if we ever do want to change the look in the future,
  // we just need to edit the config file.
  const contextValue = {
    ...settings,
    showBurgerMenu,
    setShowBurgerMenu,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed,
    navbarCollapsed,
    setNavbarCollapsed,
    navbarStyle,
    setNavbarStyle,
    toggleModal,
    isOpenSidePanel,
    setIsOpenSidePanel
  };

  return <FalconContext.Provider value={contextValue}>{props.children}</FalconContext.Provider>;
};

FalconProvider.propTypes = { children: PropTypes.node };
