import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Flex from 'falcon/components/Flex';
import { CircleStackIcon } from 'atoms/misc';

const NavbarVerticalMenuItem = ({
  iconSpanClassName = 'nav-link-icon',
  icon,
  name,
  iconColor,
  stackedIcon = false
}) => (
  <Flex align="center">
    <span className={iconSpanClassName}>
      {stackedIcon ? (
        <CircleStackIcon
          inverse={false}
          circleClassName=""
          iconClassName=""
          iconName={icon}
          circleProps={{ color: 'white' }}
          iconProps={{ color: iconColor }}
        />
      ) : (
        <FontAwesomeIcon icon={icon} />
      )}
    </span>
    <span className="nav-link-text">{name}</span>
  </Flex>
);

NavbarVerticalMenuItem.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default NavbarVerticalMenuItem;
