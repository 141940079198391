import './Table.css';

import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';

export default function Table({ ...tableProps }) {
  return <BootstrapTable bootstrap4 {...tableProps} filter={filterFactory()} pagination={paginationFactory()} />;
}
