import './styles.css';

import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import { toast } from 'react-toastify';

import ContentWithAsideLayout from 'layouts/ContentWithAsideLayout';
import Loader from 'falcon/components/Loader';
import { getCalendarItemsList } from 'api/calendar-items';
import { getCapacityReports } from 'api/capacity-reports';
import { getProjects } from 'api/projects';
import { useCalendarsListContext } from 'context/calendars';
import {
  CalendarEventTooltip,
  EditCalendarItemModal,
  useEditCalendarItemModal,
  useCalendarViews
} from 'atoms/calendars';
import { moveEvent } from 'actions/calendars';

import Calendar from './Calendar';
import { formatDate } from 'api/utils';
import Header from './Header';
import CalendarEventBox from './CalendarEventBox';
import CalendarSlotDayBox from './CalendarSlotDayBox';
import CalendarSlotDayTooltip from './CalendarSlotDayTooltip';
import { viewOptionsMap, DEFAULT_VIEW } from './views';

const setError = msg => {
  toast.error(msg);
};

export default function ProjectTimelineCalendar({ projectId = null }) {
  const { calendars } = useCalendarsListContext();
  const calendarsLookup = React.useMemo(
    () =>
      calendars.reduce((acc, calendar) => {
        acc[calendar.id] = calendar;
        return acc;
      }, {}),
    [calendars]
  );
  const [calendarApi, setCalendarApi] = React.useState();
  const [title, setTitle] = React.useState('');
  const [calendarItems, setCalendarItems] = React.useState();
  const [projects, setProjects] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [capacityReports, setCapacityReports] = React.useState();

  const transformCalendarItem = React.useCallback(
    function transformCalendarItem(calendarItem) {
      const calendar = calendarsLookup[calendarItem.calendar];
      return {
        ...calendarItem,
        resourceId: calendarItem.project.id,
        backgroundColor: calendar.calendar_event_box_color,
        borderColor: calendar.calendar_event_box_color
      };
    },
    [calendarsLookup]
  );

  React.useEffect(() => {
    setLoading(true);
    if (Object.keys(calendarsLookup).length === 0) return;
    async function loadData() {
      const [calendarItemsRes, calendarItemsErr] = await getCalendarItemsList(projectId);
      if (calendarItemsErr) {
        toast.error(calendarItemsErr);
        setCalendarItems(undefined);
        return;
      }
      setCalendarItems(calendarItemsRes.data.map(transformCalendarItem));
      const [projectsRes, projectsErr] = await getProjects();
      if (calendarItemsErr) {
        toast.error(projectsErr);
        setProjects(undefined);
        return;
      }
      setProjects(
        projectsRes.data.map(project => {
          return {
            ...project,
            title: `${project.id}: ${project.name}${project.external_id ? ` (${project.external_id})` : ''}`,
            // Fullcalendar seems to convert the ids to strings and sort by the strings
            intId: project.id
          };
        })
      );
      setLoading(false);
    }
    loadData();
  }, [calendarsLookup, transformCalendarItem, projectId]);

  const { editCalendarModalProps, handleCalendarEventClick } = useEditCalendarItemModal(
    event => calendarsLookup[event.extendedProps.calendar]
  );

  const { selectCalendarViewDropdownProps, calendarProps } = useCalendarViews(
    calendarApi,
    viewOptionsMap,
    DEFAULT_VIEW
  );

  const fetchCalendarsSum = async (startDate, endDate) => {
    if (startDate === '' || endDate === '') {
      return;
    }
    const [res, error] = await getCapacityReports(startDate, endDate);
    if (error) {
      toast.error(error);
      setCapacityReports(undefined);
      return;
    } else {
      setCapacityReports(res.data['report']);
    }
  };

  return (
    <>
      <ContentWithAsideLayout
        fullHeight
        ratio={[5, 0]}
        aside={<></>}
        banner={
          <Header
            title={title}
            calendarApi={calendarApi}
            showCsvDownloadButton={projectId === null}
            selectCalendarViewDropdownProps={selectCalendarViewDropdownProps}
          />
        }
      >
        <div className="pt-3 h-100">
          <Card className="h-100">
            {!loading ? (
              <Calendar
                resourceLabelContent={({ resource }) => <Link to={`projects/${resource.id}`}>{resource.title}</Link>}
                resourceOrder="intId"
                resourceAreaWidth={projectId !== null ? '0px' : '15%'}
                datesSet={({ view, start, end }) => {
                  fetchCalendarsSum(formatDate(start), formatDate(end));
                  // This runs whenever the calendar changes dates.
                  setTitle(view.title);
                }}
                setCalendarApi={setCalendarApi}
                events={calendarItems}
                resources={projects}
                eventClick={handleCalendarEventClick}
                eventChange={e => {
                  moveEvent(e, setCalendarItems, setError, transformCalendarItem);
                }}
                slotLabelContent={({ date, text }) => {
                  const labelDate = formatDate(date);
                  const id = `slot_day_${date.getTime()}`;
                  return (
                    <>
                      <CalendarSlotDayBox id={id} text={text} />
                      <CalendarSlotDayTooltip
                        date={labelDate}
                        calendarSumLookupById={capacityReports && capacityReports[labelDate]}
                        calendarsLookup={calendarsLookup}
                        placement="right"
                        target={id}
                        disabled={Boolean(projectId)}
                      />
                    </>
                  );
                }}
                eventContent={({ event }) => {
                  const calendarData = calendarsLookup[event.extendedProps.calendar];
                  return (
                    <>
                      <CalendarEventBox id={`event_block_${event.id}`} color={calendarData.calendar_event_box_color} />
                      <CalendarEventTooltip
                        event={event}
                        calendarData={calendarData}
                        project={event.extendedProps.project}
                        target={`event_block_${event.id}`}
                        placement="right"
                      />
                    </>
                  );
                }}
                {...calendarProps}
              />
            ) : (
              <Loader />
            )}
          </Card>
        </div>
      </ContentWithAsideLayout>
      <EditCalendarItemModal
        {...editCalendarModalProps}
        setCalendarItems={setCalendarItems}
        transformCalendarItem={transformCalendarItem}
      />
    </>
  );
}
