import { toTitleCase } from 'utils/formatters';

export const initialState = {
  user: null,
  sessionChecked: false
};

export function reducer(currentState, action) {
  switch (action.type) {
    case 'SET_USER': {
      const { project_label_singular, project_label_plural } = action.payload.userdetails.company;
      const allPermissions = action.payload.groups
        .flatMap(group => group.permissions.map(permission => permission.codename))
        .concat(action.payload.user_permissions.map(permission => permission.codename));
      return {
        ...currentState,
        user: {
          ...action.payload,
          allPermissions,
          // User is considered admin if they have the 'add_user' permission in any of their groups
          isAdmin: allPermissions.includes('add_user'),
          avatar: {
            size: 'xl',
            name: `${action.payload.first_name} ${action.payload.last_name}`,
            round: 'circle',
            src: action.payload.profile_img_url
          }
        },
        projectLabels: {
          singularTitle: toTitleCase(project_label_singular),
          pluralTitle: toTitleCase(project_label_plural),
          singular: project_label_singular,
          plural: project_label_plural
        }
      };
    }
    case 'UPDATE_COMPANY_DETAILS': {
      return {
        ...currentState,
        user: {
          ...currentState.user,
          userdetails: {
            ...currentState.user.userdetails,
            company: action.payload
          }
        }
      };
    }
    case 'CLEAR_USER': {
      return {
        ...currentState,
        user: null
      };
    }
    case 'SET_SESSION_CHECKED': {
      return {
        ...currentState,
        sessionChecked: action.payload
      };
    }
    default:
      throw new Error(`AuthProvider dispatch received unhandled dispatch action type: ${action.type}`);
  }
}
