import React from 'react';

import ButtonIcon from 'falcon/components/ButtonIcon';

import CreateUserModal from './CreateUserModal';

export default function CreateUserButton({ onSuccess = () => {}, buttonIconProps = {} }) {
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <ButtonIcon
        icon="plus"
        onClick={() => {
          setModalOpen(true);
        }}
        {...buttonIconProps}
      >
        Add User
      </ButtonIcon>
      <CreateUserModal
        open={modalOpen}
        toggle={() => {
          setModalOpen(cur => !cur);
        }}
        onSuccess={onSuccess}
      />
    </>
  );
}
