import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Form, Row, Col, FormGroup, Label } from 'reactstrap';

import { useAuthContext } from 'context/auth';

const LoginForm = ({ hasLabel }) => {
  // State
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const history = useHistory();
  const { authState, authActions } = useAuthContext();

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    await authActions.login(username, password);
  };

  useEffect(() => {
    setIsDisabled(!username || !password);
  }, [username, password]);

  useEffect(() => {
    if (authState.user !== null && authState.sessionChecked) {
      history.replace('/');
    }
  }, [authState.user, authState.sessionChecked, history]);

  return (
    <div className="container">
      <Row className="text-left justify-content-between">
        <Col xs="auto">
          <h5>Log in</h5>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          {hasLabel && <Label>Username</Label>}
          <input
            className="form-control"
            name="username"
            placeholder={!hasLabel ? 'Username' : ''}
            value={username}
            onChange={({ target }) => setUsername(target.value)}
            type="text"
          />
        </FormGroup>
        <FormGroup>
          {hasLabel && <Label>Password</Label>}
          <input
            className="form-control"
            name="password"
            placeholder={!hasLabel ? 'Password' : ''}
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            type="password"
          />
        </FormGroup>
        <FormGroup>
          <Button color="primary" block className="mt-3" disabled={isDisabled}>
            Log in
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default LoginForm;
