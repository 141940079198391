export const viewOptionsMap = new Map([
  [
    'resourceTimelineWeek',
    {
      buttonText: 'Work Week View',
      slotLabelInterval: { days: 1 },
      slotLabelFormat: [{ weekday: 'short', day: 'numeric' }],
      weekends: false
    }
  ],
  [
    'resourceTimelineMonth',
    {
      buttonText: 'Month View',
      slotLabelFormat: [{ day: 'numeric' }]
    }
  ]
]);

export const DEFAULT_VIEW = 'resourceTimelineMonth';
