import React from 'react';
import { format } from 'date-fns';

import EditableFieldWithPatchRequest from './EditableFieldWithPatchRequest';

const makeLinePrefix = () => `${format(new Date(), 'yyyy-MM-dd')}: `;

function beforeFocus(inputRef) {
  if (inputRef.current.setSelectionRange) {
    const selectionTarget = makeLinePrefix().length;
    inputRef.current.setSelectionRange(selectionTarget, selectionTarget);
  }
}

export default function DatedNoteTextArea({
  groupedInputProps = {
    type: 'textarea',
    showLabelText: false,
    rows: 5
  },
  groupedInputClassName = '',
  ...editableFieldWithPatchRequestProps
}) {
  function handleStartEditing(setNewValue) {
    setNewValue(currentNote => {
      const linePrefix = makeLinePrefix();
      if (currentNote) return `${linePrefix}\n${currentNote}`;
      return linePrefix;
    });
  }

  return (
    <EditableFieldWithPatchRequest
      beforeFocus={beforeFocus}
      onStartEditing={handleStartEditing}
      groupedInputProps={groupedInputProps}
      groupedInputClassName={groupedInputClassName}
      {...editableFieldWithPatchRequestProps}
    />
  );
}
