import React from 'react';
import DatePicker from 'react-datepicker';

const CustomInput = React.forwardRef(function CustomInput(props, ref) {
  return <input ref={ref} {...props} className="form-control" />;
});

const FlexWrapper = ({ children }) => <div className="d-flex">{children}</div>;

const DateInput = React.forwardRef(function DateInput({ hasAddon, value, id, ...datePickerProps }, ref) {
  const Wrapper = hasAddon ? React.Fragment : FlexWrapper;
  return (
    <Wrapper>
      <DatePicker
        customInput={<CustomInput />}
        ref={ref}
        id={id}
        selected={value}
        dateFormat="yyyy-MM-dd"
        {...datePickerProps}
      />
    </Wrapper>
  );
});

export default DateInput;
