import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import ButtonIcon from 'falcon/components/ButtonIcon';

import CreateUserForm from './CreateUserForm';

const FORM_ID = 'create-user-form';

export default function CreateUserModal({ open, toggle, onSuccess }) {
  return (
    <Modal isOpen={open} toggle={toggle} modalClassName="theme-modal" contentClassName="border">
      <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0">
        Create New User
      </ModalHeader>
      <ModalBody>
        <CreateUserForm
          id={FORM_ID}
          onSuccess={newUser => {
            onSuccess(newUser);
            toggle();
          }}
        />
      </ModalBody>
      <ModalFooter>
        <ButtonIcon icon="check" color="falcon-primary" type="submit" className="px-4" form={FORM_ID}>
          Save
        </ButtonIcon>
      </ModalFooter>
    </Modal>
  );
}
