import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useMount } from 'falcon/helpers/utils';
import { useAuthContext } from 'context/auth';

import rocket from './rocket.png';

const LogoutContent = ({ titleTag: TitleTag }) => {
  const { authActions } = useAuthContext();
  useMount(() => {
    async function awaitLogout() {
      await authActions.logout();
    }
    awaitLogout();
  });

  return (
    <div className="text-center container">
      <img className="d-block mx-auto mb-4" src={rocket} alt="shield" width={70} />
      <TitleTag>See you again!</TitleTag>
      <p>
        You are <br className="d-none d-sm-block" />
        now successfully signed out.
      </p>
      <Button tag={Link} color="primary" size="sm" className="mt-3" to={`/login`}>
        <FontAwesomeIcon icon="chevron-left" transform="shrink-4 down-1" className="mr-1" />
        Return to Login
      </Button>
    </div>
  );
};

LogoutContent.propTypes = {
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

LogoutContent.defaultProps = {
  layout: 'basic',
  titleTag: 'h4'
};

export default LogoutContent;
