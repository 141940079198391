import React from 'react';
import ButtonIcon from 'falcon/components/ButtonIcon';
import { toast } from 'react-toastify';

import { useToastError } from 'utils';
import { deleteProject } from 'api/projects';
import { useAuthContext } from 'context/auth';

export default function DeleteProjectButton({ id, onSuccess = () => {}, ...buttonIconProps }) {
  const setError = useToastError();
  const {
    authState: {
      projectLabels: { singularTitle }
    }
  } = useAuthContext();

  async function handleDelete() {
    if (!window.confirm(`Delete ${singularTitle}? This will delete all related calendar items.`)) return;

    const [, responseError] = await deleteProject(id);
    if (responseError) {
      setError(`An error occurred and deleting the ${singularTitle} failed.`);
      return;
    }
    toast.success(`${singularTitle} deleted.`);
    onSuccess(id);
  }

  return (
    <ButtonIcon onClick={handleDelete} {...buttonIconProps}>
      Delete
    </ButtonIcon>
  );
}
