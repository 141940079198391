import React from 'react';
import { Collapse, NavItem, NavLink } from 'reactstrap';

import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';

export default function NavbarVerticalDropdown({ children, icon, name, initialIsOpen = false }) {
  const [isOpen, setIsOpen] = React.useState(initialIsOpen);
  return (
    <NavItem>
      <NavLink
        tag="div"
        onClick={() => setIsOpen(!isOpen)}
        className="dropdown-indicator cursor-pointer nav-link"
        aria-expanded={isOpen}
      >
        <NavbarVerticalMenuItem icon={icon} name={name} />
      </NavLink>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </NavItem>
  );
}
