import { checkSession as apiCall } from 'api';
import { toast } from 'react-toastify';

export default function checkSession(authDispatch) {
  return async function checkSessionAction() {
    const [response, error] = await apiCall();
    if (!error) {
      authDispatch({
        type: 'SET_USER',
        payload: response.data
      });
      toast.success(`Logged in as ${response.data.username}`);
    }
    authDispatch({
      type: 'SET_SESSION_CHECKED',
      payload: true
    });
  };
}
